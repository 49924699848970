export default [
  {
    id: "PEN",
    name: 'Sin respuesta',
    detail: 'Pendientes',
    summary: 'Sin respuesta',
    color: 'yellowLight',
    icon: 'mdi-clock ',
    key: 'Otros'
  },
  {
    id: "RCD",
    name: 'Reclamado',
    detail: 'Reclamo al Contenido del Documento',
    summary: 'Reclamado',
    color: 'redLight',
    icon: 'mdi-close',
    key: 'Reclamado'
  },
  {
    id: "RFP",
    name: 'Reclamado',
    detail: 'Reclamo por Falta Parcial de Mercaderías',
    summary: 'Reclamado',
    color: 'redLight',
    icon: 'mdi-close',
    key: 'Reclamado'
  },
  {
    id: "RFT",
    name: 'Reclamado',
    detail: 'Reclamo por Falta Total de Mercaderías',
    summary: 'Reclamado',
    color: 'redLight',
    icon: 'mdi-close',
    key: 'Reclamado'
  },
  {
    id: "ERM",
    name: 'Aceptado',
    detail: 'Otorga Recibo de Mercaderías o Servicios',
    summary: 'Aceptado',
    color: 'lightBlue',
    icon: 'mdi-check',
    key: 'Otros'
  },
  {
    id: "PAG",
    name: 'Aceptado',
    detail: 'DTE pagado al contado',
    summary: 'Aceptado',
    color: 'lightBlue',
    icon: 'mdi-check',
    key: 'Otros'
  },
  {
    id: "ACD",
    name: 'Aceptado',
    detail: 'Acepta Contenido del Documento',
    summary: 'Aceptado',
    color: 'lightBlue',
    icon: 'mdi-check',
    key: 'Otros'
  },
  {
    id: "INV",
    detail: '',
    color: 'grey-200',
    summary: 'Inválido',
    icon: 'mdi-alert-circle',
    key: ''
  }
  // {
  //   id: "NCA",
  //   detail: 'Recepción de NC de anulación que referencia al documento',
  //   color: 'greyMedium',
  //   summary: 'Anulado',
  //   icon: 'mdi-file-remove'
  // }
  // {
  //   id: "ENC",
  //   detail: 'Recepción de NC, distinta de anulación, que referencia al documento.',
  //   color: 'greyMedium',
  //   summary: 'Anulado',
  //   icon: 'mdi-file-remove'
  // }
]