<template>
  <v-card :flat="flat" :min-height="!renderUrl ? 200 : 300">
    <template v-if="showToolbar">
      <v-toolbar class="transparent" flat height="46">
        <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-medium">PDF {{documentType}} Nº {{number}}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center" justify="center" class="mr-4">
            <v-btn @click="$emit('close')" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider />
    </template>
    <v-card-text class="pa-0" :style="`min-height: ${!renderUrl ? 200 : 500}px !important`">
      <v-alert v-if="alert && !loaderRender && renderUrl" class="body-2 py-3 mb-0" dense tile color="fontBody" dark>{{alert}}</v-alert>
      <v-row v-if="loaderRender" no-gutters>
        <v-col cols="12" class="pa-0 ma-0">
          <VSkeleton />
        </v-col>
      </v-row>
      <v-empty-state class="py-16" v-else-if="!renderUrl" title="PDF no disponible" :height="100" type="pdf" customClass="mt-6" :isFree="true" />
      <div v-show="!loaderRender && renderUrl" id="render"></div>
    </v-card-text>
    <template v-if="!loaderRender && renderUrl">
      <v-divider />
      <v-card-actions class="px-5 py-2 white">
        <v-spacer />
        <v-col cols="3" class="pa-0">
          <v-select class="mr-2" v-model="currentType" v-if="showFormat" :items="formatTypes" item-value="value" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
        </v-col>
        <v-btn :href="renderUrl" :download="name" color="primary" dark>Descargar PDF</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import VEmptyState from '@/components/commons/VEmptyState'
import VSkeleton from '@/components/commons/skeletonLoader/VSkeletonPDF'

export default {
  props: {
    modalRender: {
      type: Boolean,
      default: false
    },
    loaderRender: {
      type: Boolean,
      default: false
    },
    renderUrl: {
      default: null
    },
    documentType: {
      type: String,
      default: ''
    },
    number: {
      default: ''
    },
    flat: {
      type: Boolean,
      default: true
    },
    showToolbar: {
      type: Boolean,
      default: true
    },
    showFormat: {
      type: Boolean,
      default: false
    },
    formatPDF: {
      type: Number,
      default: 1
    },
    alert: {
      type: String,
      default: ''
    }
  },
  components: { VEmptyState, VSkeleton },
  data: () => ({
    formatTypes: [
      {
        value: 1,
        name: 'Tipo carta'
      },
      {
        value: 57,
        name: 'Impresión térmica 57mm'
      },
      {
        value: 75,
        name: 'Impresión térmica 75mm'
      },
      {
        value: 80,
        name: 'Impresión térmica 80mm'
      },
      {
        value: 110,
        name: 'Impresión térmica 110mm'
      }
    ]
  }),
  computed: {
    currentType: {
      get () {
        return this.formatPDF
      },
      set (val) {
        this.$emit('update:formatPDF', val)
      }
    },
    name () {
      const n = `${this.documentType}_${typeof this.number === 'number' ? this.number : this.number.replace(/[ ]/g, '')}`
      return n.replace(/[ ]/g, '_')
    }
  }
}
</script>