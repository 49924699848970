<template>
  <div class="text-center">
    <!-- dialog labels list-->
    <v-dialog v-model="assignDialog" width="741" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat outlined rounded>
        <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">{{ `${actionLabel === 'edit' ? 'Actualizar etiquetas del' : 'Etiquetar'} documento` }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="closeModal(), currentLabel = {}" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 pt-4 pb-6">
          <v-row class="pt-2" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-create-labels-fl.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-3">
              <v-list-item-subtitle>
                <v-col class="pa-0" >
                  <v-list class="v-list-form-extended transparent pa-0">
                    <v-list-item class="py-2">
                      <v-list-item-title>Etiqueta</v-list-item-title>
                      <v-list-item-subtitle class="ml-n8">
                        <v-col class="pa-0">
                          <v-autocomplete v-model="currentLabel" :items="list" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" return-object single-line outlined dense hide-details>
                            <template v-slot:item="data">
                              <v-list-item-title class="font-weight-regular">
                                <v-icon class="mr-2 icon-label" :style="`color: ${data.item.color} !important`" size="18">mdi-circle</v-icon>
                                {{data.item.name}}
                              </v-list-item-title>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="closeModal(), currentLabel = {}" outlined>Cancelar</v-btn>
          <v-btn @click="$emit('labelSelected', { label: currentLabel, action: 'assign'}), currentLabel = {}" color="primary" :loading="loadingLabels">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog labels list-->

    <!-- dialog labels create-->
    <v-dialog v-model="createDialog" width="741" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat outlined rounded>
        <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear etiqueta</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="closeModal" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 py-4" :style="`${labelsList.length >= 3 ? 'max-height: 600px' : ''};`">
          <v-row class="pt-2" align="center">
            <v-col cols="3" v-if="labelsList.length === 1">
              <img :src="require(`@/assets/backgrounds/modal-create-labels-fl.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-3 pl-3">
              <v-list-item-subtitle>
                <v-row v-for="(label, i) in labelsList" :key="label.id" align="start" no-gutters>
                  <v-col class="pa-0">
                    <v-list class="v-list-form-extended transparent pa-0">
                      <v-list-item class="py-2">
                        <v-list-item-title>Nombre</v-list-item-title>
                        <v-list-item-subtitle class="ml-n10">
                          <v-text-field v-model="label.name" outlined hide-details required single-line dense maxlength="32" />
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                    <v-col class="pa-0 mt-5" cols="4">
                      <span class="body-1 fontBody--text font-weight-bold">Color de la etiqueta</span>
                      <div class="mt-3 mb-5 d-dlex">
                        <v-btn class="mr-2" v-for="color in colorsList" @click="label.color = color" :key="color" text icon width="22" height="22" :style="color === label.color ? 'border: 2px solid var(--light-blue-primary)' : ''">
                          <v-icon class="ma-auto" :color="color" size="18">mdi-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-col>
                  <div class="pl-4 pt-2" style="max-width:40px;">
                    <v-btn v-if="i >= 1 || labelsList.length > 1" @click="labelsList.splice(i, 1)" text :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                  </div>
                </v-row>
                <v-row  no-gutters>
                </v-row>
                <v-row no-gutters>
                  <v-col class="pa-0">
                    <v-btn class="mt-n1" v-if="labelsList.length < 7" @click.prevent="addRowLabels" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                      <span class="font-weight-medium body-1">Agregar otra etiqueta</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="closeModal" outlined>Cancelar</v-btn>
          <v-btn @click="createLabels" color="primary" :loading="loadingLabels">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog labels create-->

    <!-- dialog delete labels-->
    <v-dialog v-model="deleteDialog" width="741" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat outlined rounded>
        <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Eliminar etiqueta</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3" no-gutters>
              <v-btn @click="closeModal(), currentLabel = {}, idDeleteLabel = null" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 pt-5 pb-5">
          <v-row class="pt-2" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-create-labels-fl.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-3">
              <span class="font-weight-medium body-1">Selecciona la etiqueta que desea eliminar de los documentos seleccionados</span>
              <div class="mt-6 mb-5 d-dlex">
                <v-btn class="mr-3 mb-5" v-for="label in currentLabelsPerDoc" :key="label.id" @click="selectedLabel(label)" text height="22" >
                  <v-chip class="chip-labels chip-labels-hover" :outlined="idDeleteLabel === label.id" :color="idDeleteLabel !== label.id ? 'bgSearch' : ''">
                    <v-icon :color="label.color">mdi-circle</v-icon>
                    <span class="ml-2 body-2 fontBody--text d-inline-block text-truncate">{{label.name}}</span>
                  </v-chip>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="closeModal(), currentLabel = {}, idDeleteLabel = null" outlined>Cancelar</v-btn>
          <v-btn @click="deleteDialog=false, confirmDialog=true" color="primary" :disabled="!(idDeleteLabel || '').length">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog delete labels -->

    <!-- dialog confirm label delete -->
    <v-dialog v-model="confirmDialog" width="792" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Eliminar etiqueta</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="closeModal(), confirmDialog=false, idDeleteLabel=null" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-create-labels-fl.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <span class="d-block body-1 pt-2" v-if="key !== 'destroy'">Estás eliminando la etiqueta "{{ currentLabel.name }}" de los documentos seleccionados.</span>
              <span class="d-block body-1 pt-2" v-else>Al eliminar la etiqueta "{{ currentLabel.name }}", se eliminará de todos los documentos marcados con esta etiqueta.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="closeModal(), confirmDialog=false, idDeleteLabel=null" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="handlerConfirm" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm label delete -->

    <!-- dialog setup labels-->
    <v-dialog v-model="setupDialog" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat outlined rounded>
        <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Gestionar etiqueta</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3" no-gutters>
              <v-btn @click="closeModal(), currentLabel = {}, idDeleteLabel = null" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 pt-5 pb-5">
          <!-- <v-empty-state v-if="!list.length" type="labels" id="pos" customClass="mt-5 mb-n4" :height="150" :isFree="true"  title="No tienes etiquetas creadas" description="Crea etiquetas ..." /> -->
          <v-col class="pa-0">
            <div class="mt-5 mb-5 d-dlex">
              <v-btn class="mr-3 mb-8" v-for="label in list" :key="label.id" @click="selectedLabel(label)" text height="22" >
                <v-chip class="chip-labels chip-labels-hover" :outlined="idDeleteLabel === label.id" :color="idDeleteLabel !== label.id ? 'bgSearch' : ''">
                  <v-icon :color="label.color">mdi-circle</v-icon>
                  <span class="ml-2 body-2 fontBody--text d-inline-block text-truncate">{{label.name}}</span>
                </v-chip>
              </v-btn>
            </div>
            <v-row class="mt-n4" align="center" no-gutters>
              <v-col cols="2" class="pt-0">
               Nombre
              </v-col>
              <v-col class="pt-0">
                <v-text-field v-model="currentLabel.name" outlined hide-details required single-line dense maxlength="32" />
              </v-col>
            </v-row>
            <v-col class="pa-0 mt-5" cols="4">
              <span class="body-1 fontBody--text font-weight-bold">Color de la etiqueta</span>
              <div class="mt-3 mb-5 d-dlex">
                <v-btn class="mr-2" v-for="color in colorsList" @click="currentLabel.color = color" :key="color" text icon width="22" height="22" :style="color === currentLabel.color ? 'border: 2px solid var(--light-blue-primary)' : ''">
                  <v-icon class="ma-auto" :color="color" size="18">mdi-circle</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-btn v-if="(currentLabel || {}).id" @click="handlerSetupLabel('destroy')" text :ripple="false" color="primary">Eliminar etiqueta<v-icon color="primary" right>mdi-delete</v-icon></v-btn>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="closeModal(), currentLabel = {}, idDeleteLabel = null" outlined>Cancelar</v-btn>
          <v-btn @click="handlerSetupLabel" color="primary" :disabled="!(currentLabel || {}).id">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog setup labels-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import VEmptyState from '@/components/commons/VEmptyState'

export default {
  components: {
    VEmptyState
  },
  props: {
    headers: {
      type: Array,
      default: () => ([])
    },
    actionLabel: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    activeLabels: {
      type: Boolean,
      default: false
    },
    labelsPerDoc: {
      type: Array,
      default: () => ([])
    },
    selectedDocumentsList: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    key: '',
    confirm: '',
    confirmDialog: false,
    idDeleteLabel: null,
    currentLabel: {},
    loadingLabels: false,
    createDialog: false,
    editDialog: false,
    assignDialog: false,
    deleteDialog: false,
    setupDialog: false,
    labelsList: [{
      name: '',
      color: '#0072CE'
    }],
    colorsList: ['#0072CE', '#83CC14', '#F8BF00', '#E60909', '#9FA8DA', '#525F7F']
  }),
  watch: {
    activeLabels: {
      handler (val) {
        this[`${this.actionLabel}Dialog`] = val
        if (['assign', 'setup', 'edit'].includes(this.actionLabel)) {
          this.getList()
        }
        if (this.actionLabel === 'delete') {
          this.labelsList = []
          this.selectedDocumentsList.forEach(({labels}) => {
            this.labelsList = [...this.labelsList, ...labels]
          })
          if (this.selectedDocumentsList.length > 1) {
            let labels = this.labelsList.map(({name}) => name)
            let frequencies = labels?.reduce((acc, label) => {
              acc[label] = (acc[label] || 0) + 1
              return acc
            }, {})

            let repeatedLabels = Object.keys(frequencies).filter(label => frequencies[label] > 1)
            this.labelsList = this.labelsList.filter(({name}) => repeatedLabels.includes(name))
            // We eliminate possible duplicates
            let aux = {}
            // eslint-disable-next-line no-return-assign
            this.labelsList = this.labelsList.filter((w) => (aux[w.id] ? false : aux[w.id] = true))
          }
        }
      },
      inmediate: true,
      deep: true
    },
    selectedDocumentsList: {
      handler (val) {
        // df
      },
      inmediate: true
    }
  },
  computed: {
    ...mapState({
      list: state => state.labels.labelsList
    }),
    currentLabelsPerDoc () {
      return !this.selectedDocumentsList.length ? this.labelsPerDoc : this.labelsList
    }
  },
  mounted () {
  },
  methods: {
    handlerSetupLabel (key = '') {
      this.setupDialog = false
      this.key = key
      if (key !== 'destroy') this.updateLabel()
      else this.confirmDialog = true
    },
    handlerConfirm () {
      if (this.idDeleteLabel.length && !this.labelsPerDoc.length) {
        this.destroyLabel()
      } else {
       this.$emit('labelSelected', { label: this.currentLabel, action: 'delete'})
      }

      this.currentLabel = {}
      this.confirmDialog = false
      this.confirm = ''
      this.idDeleteLabel = null
    },
    selectedLabel (label = null) {
      this.currentLabel = {...label}
      if (this.idDeleteLabel === label.id) {
        this.idDeleteLabel = null
        this.currentLabel = {}
      } else this.idDeleteLabel = label.id
    },
     addRowLabels () {
      this.labelsList.push({
        color: '#0072CE',
        name: ''
      })
    },
    getList () {
      // this.loadingLabels = true
      this.$store.dispatch('labels/LIST', {
        resource: 'labels'
      })
      .then((resp) => {
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.non_field_errors)
      })
      .finally(() => {
        // this.loadingLabels = false
      })
    },
    createLabels () {
      this.loadingLabels = true
      this.$store.dispatch('labels/CREATE', {
        resource: 'labels',
        payload: this.labelsList
      })
      .then((resp) => {
        this.$dialog.message.info('La etiqueta fue creada con éxito')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.error)
      })
      .finally(() => {
        this.loadingLabels = false
        this.closeModal()
        this.labelsList = [{
          color: '#0072CE',
          name: ''
        }]
        this.$emit('getLabels')
      })
    },
    updateLabel () {
      this.loadingLabels = true
      this.$store.dispatch('labels/UPDATE', {
        resource: 'labels',
        id: this.currentLabel.id,
        payload: this.currentLabel
      })
      .then(() => {
        this.$dialog.message.info('La etiqueta fue actualizada con éxito')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.error)
      })
      .finally(() => {
        this.loadingLabels = false
        this.currentLabel = {}
        this.closeModal()
        this.$emit('getList')
      })
    },
    destroyLabel () {
      this.loadingLabels = true
      this.$store.dispatch('labels/DESTROY', {
        resource: 'labels',
       id: this.currentLabel.id
      })
      .then((resp) => {
        this.$dialog.message.info('La etiqueta fue eliminada con éxito')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.error)
      })
      .finally(() => {
        this.loadingLabels = false
        this.closeModal()
        this.key = ''
        this.idDeleteLabel = null
        this.currentLabel = {}
        this.$emit('getList')
      })
    },
    closeModal () {
      this[`${this.actionLabel}Dialog`] = false
      this.$emit('update:actionLabel', '')
      this.$emit('update:activeLabels', false)
      this.key = ''
    }
  }
}
</script>