<template>
  <v-tooltip color="bgSearch" right transition="scale-transition" :max-width="280" :open-on-hover="(isTooltip && !!activeStatus?.detail) && (['DocumentsList', 'PurchaseList', 'PendingReceiveList'].includes($route.name) && activeStatus?.detail.length > 15) || (isTooltip && !!activeStatus?.detail) && (['DocumentsRetrieve', 'DocumentsBatchRetrieve'].includes($route.name) && activeStatus?.detail.length > 15)">
    <template v-slot:activator="{on}">
      <v-chip v-if="activeStatus" v-on="(isTooltip && !!activeStatus?.detail) && (['DocumentsList', 'PurchaseList', 'PendingReceiveList'].includes($route.name) && activeStatus?.detail.length > 15) || (isTooltip && !!activeStatus?.detail) && (['DocumentsRetrieve', 'DocumentsBatchRetrieve'].includes($route.name) && activeStatus?.detail.length > 15) ? on : false" :color="activeStatus.color" small label :class="{'px-2' : activeShortMode.includes($route.name)}">
        <!-- <template v-if="activeStatus.isLight || activeShortMode.includes($route.name)">{{activeShortMode.includes($route.name) ? activeStatus.id : activeStatus.name}}</template> -->
        <!-- <template v-else> -->
          <span class="d-inline-block text-truncate" style="max-width: 170px;" v-if="['DocumentsList', 'PendingReceiveList', 'PurchaseList', 'DocumentsBatchRetrieve'].includes($route.name) || showDetail">
            <template v-if="['DocumentsList', 'PurchaseList', 'DocumentsRetrieve', 'PurchaseRetrieve', 'PendingReceiveList', 'DocumentsBatchRetrieve'].includes($route.name)">
            <!-- <span v-if="(activeStatus || {}).detail === 'Enviado a SII'">{{ $t('generals.Enviado a SII') }}</span> -->
            <span>{{ $route.name === 'DocumentsRetrieve' ? activeStatus?.detail || activeStatus.summary : activeStatus.summary }}</span>
          </template>
          <!-- <span v-else>{{activeStatus.name}}</span> -->
          </span>
          <span v-else-if="showSummary">{{activeStatus.summary}}</span>
        <!-- </template> -->
        <span class="d-block px-3 py-2" v-if="showCode">{{activeStatus?.id}}</span>
        <v-icon v-if="!showCode" :right="!activeShortMode.includes($route.name) " :size="activeShortMode.includes($route.name) ? '18' : 'auto'">{{activeStatus.icon}}</v-icon>
      </v-chip>
    </template>
    <span class="d-block px-3 py-2" v-if="(activeStatus || {}).detail === 'Enviado a SII'">{{ $t('generals.Enviado a SII') }}</span>
    <span class="d-block px-3 py-2" v-else>{{activeStatus?.detail}}</span>
  </v-tooltip>
</template>
<script>
import documentStatuses from '@/collections/documentStatuses'
import purchaseStatuses from '@/collections/purchaseStatuses'

export default {
  props: {
    status: {
      type: null,
      required: true
    },
    isTooltip: {
      type: Boolean,
      deault: false
    },
    showDetail: {
      type: Boolean,
      deault: false
    },
    showSummary: {
      type: Boolean,
      deault: false
    },
    showCode: {
      type: Boolean,
      deault: false
    },
    type: {
      type: String,
      required: ''
    }
  },
  data: () => ({
    activeShortMode: ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'SupplierRetrieveUpdate', 'CustomerRetrieveUpdate', 'PurchaseRetrieve'],
    documentStatuses: documentStatuses,
    purchaseStatuses: purchaseStatuses
  }),
  computed: {
    activeStatus () {
      return this.currentStatusesList().find(s => s.id === this.status) ?? this.status
    }
  },
  mounted () {
    this.currentStatusesList()
  },
  methods: {
    currentStatusesList () {
      if (['DocumentsList', 'DocumentsRetrieve', 'PurchaseRetrieve', 'BranchRetrieveUpdate', 'PosRetrieveUpdate', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve'].includes(this.$route.name) && this.type !== 'purchases') return this.documentStatuses?.filter(({countries}) => countries.includes(this.$store?.state?.auth?.account?.country)) ?? []
      return this.purchaseStatuses
    }
  }
}
</script>